module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2000,"quality":90,"backgroundColor":"none","linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://blog.mattianatali.dev"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-ZZWL81G3CQ"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":true,"respectDNT":true,"exclude":["/preview/**"],"delayOnRouteUpdate":0,"origin":"https://www.googletagmanager.com"}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
